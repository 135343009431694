import React, { useEffect, Suspense } from "react";

import {
  Outlet, Route,
  RouterProvider, createBrowserRouter,
  createRoutesFromElements
} from "react-router-dom";

import { wrapCreateBrowserRouter } from "@sentry/react";

import Layout                 from './components/Layout';
import LoadingSpinner         from './components/atoms/LoadingSpinner';
import { RouteErrorBoundary } from './components/ErrorBoundary';
import { DocumentProvider }   from './context';
import { usePageView, useTheme } from './hooks';

/*public*/
const Document  = React.lazy(() => import('./pages/Document'));
const ErrorPage = React.lazy(() => import('./pages/Error'));
const Finished  = React.lazy(() => import('./pages/Finished'));
const Verify    = React.lazy(() => import('./pages/Verify'));
const Terms     = React.lazy(() => import('./pages/Terms'));
const VOI       = React.lazy(() => import('./pages/VOI'));
const Checklist = React.lazy(() => import('./pages/Checklist'));
const Utilities = React.lazy(() => import('./pages/Utilities'));

import config from './config';

function SigningWithContext (props) {
  useTheme();

  return (
    <DocumentProvider>
      <Outlet />
    </DocumentProvider>
  )
}

function LoadingFallback () {
  return <div className="py-8"><LoadingSpinner /></div>;
}

function AppLayout (props) {
  usePageView();

  return (
    <Outlet />
  );
}

export const router = wrapCreateBrowserRouter(createBrowserRouter)(createRoutesFromElements(
    <Route 
      errorElement={<RouteErrorBoundary />} 
      element={<AppLayout />}
    >
      <Route   path="/"           element={<SigningWithContext />}>
        <Route index              element={<Suspense fallback={<LoadingFallback />}><Verify /></Suspense>} />
        <Route path="voi"         element={<Suspense fallback={<LoadingFallback />}><VOI /></Suspense>} />
        <Route path="utilities"   element={<Suspense fallback={<LoadingFallback />}><Utilities /></Suspense>} />
        
        <Route path="checklist" element={<Suspense fallback={<LoadingFallback />}><Checklist /></Suspense>} />
        <Route path="document"  element={<Suspense fallback={<LoadingFallback />}><Document /></Suspense>} />
        <Route path="finished"  element={<Suspense fallback={<LoadingFallback />}><Finished /></Suspense>} />
      
      </Route>
      <Route path ="/terms"    element={<Suspense fallback={<LoadingFallback />}><Terms /></Suspense>} />
      <Route path="/error"     element={<Suspense fallback={<LoadingFallback />}><ErrorPage /></Suspense>} />  
      <Route path="/*"         element={<ErrorPage />} />  
    </Route>
  )
);

export default function AppRouter () {
  return <RouterProvider router={router} />
};