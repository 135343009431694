import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactGA from "react-ga4";

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import config from './config';

dayjs.extend(utc);
dayjs.extend(timezone);

import('./sentry');
import './index.css';
import './aria.css';
import App from './App'
import { AppProvider } from "./context";
import { QueryProvider } from "./query";

import riveWasmUrl from '@rive-app/canvas/rive.wasm?url';
import { RuntimeLoader } from '@rive-app/react-canvas';
import { ErrorBoundary } from "./components/ErrorBoundary";

RuntimeLoader.setWasmUrl(riveWasmUrl);
ReactGA.initialize(config.ga.trackingId, config.ga);

ReactDOM
  .createRoot(document.getElementById('root'))
  .render(
    <ErrorBoundary>
      <QueryProvider>
        <AppProvider>
          <App />
        </AppProvider>
      </QueryProvider>
    </ErrorBoundary>
  )
