import config from '@configs/development';
import common from '@configs/common';

const queryParams = new URLSearchParams(window.location.search);
const platform    = (queryParams.get('platform') || '');
const { API_URL, checklistEnabled, declineEnabled, theme } = (config.platforms[platform.toUpperCase()] || config.platforms.SA);

export default {
  env: 'development',
  ...common,
  ...config,
  API_URL,
  declineEnabled: !!declineEnabled,
  checklistEnabled: !!checklistEnabled,
  theme: { ...common.theme, ...(theme || {})}
};