// REGEX
export const NUM_REGEX        = /^[1-9]\d*$/;
export const DECIMAL_REGEX    = /^[0-9]+(\.[0-9]+)?$/;
export const EMAIL_REGEX      = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const POSTCODE_REGEX   = new RegExp(/^[0-9]+$/); 
export const MOBILE_REGEX     = new RegExp(/^(\+?(61|64)|0)?4\d{8}$/);
export const SPACES_REGEX     = /\s+/g;

//TIME
export const TIMEZONE         = 'Australia/Adelaide';

// TOASTS
export const TOAST_ERROR      = 'ERROR';
export const TOAST_WARNING    = 'WARNING';
export const TOAST_SUCCESS    = 'SUCCESS';

// KEYS
export const KEY_BACKSPACE    = "Backspace";
export const KEY_ENTER        = "Enter";
export const KEY_NUMPAD_ENTER = 'NumpadEnter'
// FIELDS
export const DATE_FIELD       = 'DATE_FIELD';
export const NAME_FIELD       = 'NAME_FIELD';
export const SIGNATURE_FIELD  = 'SIGNATURE_FIELD';
export const INITIAL_FIELD    = 'initial';

//SIGNING
export const POLL_TIME_MS     = 5000;

// DIALOGS
export const SIGNATURE_DIALOG = 'SIGNATURE_DIALOG';
export const COMPLETE_DIALOG  = 'COMPLETE_DIALOG';
export const MESSAGE_DIALOG   = 'MESSAGE_DIALOG';
export const AGENT_PANEL      = 'AGENT_PANEL';
export const CLEAR_DIALOG     = 'CLEAR_DIALOG';
export const ERROR_DIALOG     = 'ERROR_DIALOG';
export const PROGRESS_DIALOG  = 'PROGRESS_DIALOG';
export const TERMS_DIALOG     = 'TERMS_DIALOG';
export const DECLINE_DIALOG   = 'DECLINE_DIALOG';
export const SIGNED_DIALOG    = 'SIGNED_DIALOG';  

// VERIFY
export const VERIFY_MOBILE    = 'VERIFY_MOBILE';
export const VERIFY_OTP       = 'VERIFY_OTP';
export const VERIFIED         = 'VERIFIED';

export const STEP_MAP = {
  [VERIFY_MOBILE]: 0,
  [VERIFY_OTP]: 1,
  [VERIFIED]: 2
};

// ERROR/VALIDATION
export const UNKNOWN_ERROR    = 'UNKNOWN_ERROR';
export const ADOPT_SIGNATURE  = 'ADOPT_SIGNATURE';
export const CREATE_SIGNATURE = 'CREATE_SIGNATURE';
export const MISSING_PARAMS   = 'MISSING_PARAMS';
export const VALIDATION       = 'VALIDATION';
export const COMPLETE_SIGNING = 'COMPLETE_SIGNING';
export const VALIDATE_MOBILE  = 'VALIDATE_MOBILE';
export const VALIDATE_OTP     = 'VALIDATE_OTP';
export const CREATE_INITIALS  = 'CREATE_INITIALS';

export const E_OTP_REQUIRED   = 'E_OTP_REQUIRED';
export const E_MOBILE_NUMBER_REQUIRED = 'E_MOBILE_NUMBER_REQUIRED';

// MEDIA/BREAKPOINTS
export const SCREEN_SMALL     = 640
export const SCREEN_MEDIUM    = 768
export const SCREEN_LARGE     = 1024
export const SCREEN_XLARGE    = 1280

//CANVAS
export const REM_PX           = 16;
export const DIALOG_PADDING   = REM_PX * 2;
export const DIALOG_MARIGIN   = REM_PX * 2;
export const CANVAS_MAX_SIZE  = 520;
export const CANVAS_HEIGHT    = 128;
export const MIN_SIG_AREA     = 64 * 32;
export const MIN_INITALS_AREA = 32 * 32;

//ACTIONS
export const CLEAR            = 'CLEAR';
export const EDIT             = 'EDIT';
export const DECLINE_SIGN     = 'DECLINE_SIGN';
export const DOWNLOAD_PDF     = 'DOWNLOAD_PDF';
export const SIGN_CHECKLIST   = 'SIGN_CHECKLIST';

export const PAGE_BPS = {
  BASE   : 1.0,
  LARGE  : 1.5,
  XLARGE : 2.0
}


export const BASE_HEIGHT = 1124;
export const BASE_WIDTH  = 795;

//FONTS
export const FASTHAND_FONT = 'FastHand';
export const SANSILK_FONT  = 'Sansilk';
