import { useState, useCallback, useContext, createContext } from 'react';

import config from '/src/config';

import {
  SIGNED_DIALOG,
  ERROR_DIALOG, TERMS_DIALOG,
  AGENT_PANEL, MESSAGE_DIALOG,
  BASE_WIDTH, BASE_HEIGHT,
  COMPLETE_DIALOG, SIGNATURE_DIALOG,
  PROGRESS_DIALOG, DECLINE_DIALOG
} from '../constants';

const DocumentStateContext       = createContext();
const DocumentDispatchContext    = createContext();

const INITIAL_STATE = {
  pageScale:          1,

  init:               false,
  signed:             {},
  lastStamp:          null,
  activeSignature:    null,
  signatureInit:      false,
  initialsInit:       false,

  signatureData:      null,
  initialsData:       null,
  signatureType:      null,
  initalsText:        '',
  signatureText:      '',

  complete:           false,
  
  [ERROR_DIALOG]:     false,
  [SIGNATURE_DIALOG]: false,
  [COMPLETE_DIALOG]:  false,
  [MESSAGE_DIALOG]:   true,
  [AGENT_PANEL]:      true,
  [PROGRESS_DIALOG]:  false,
  [TERMS_DIALOG]:     false,
  [DECLINE_DIALOG]:   false,
  [SIGNED_DIALOG]:    false
};

export const useDocumentState    = () => useContext(DocumentStateContext);
export const useDocumentDispatch = () => useContext(DocumentDispatchContext);

export default function DocumentProvider (props) {
  const [ state, setState ] = useState(INITIAL_STATE); 

  function setKeyValue (key, value) {
    setState(s => ({ ...s, [key]: value }))
  }

  function closeDialog (key) {
    setKeyValue(key, false)
  }

  function openDialog (key) {
    setKeyValue(key, true);
  }

  function setSignOnSignature (id) {
    setState(s => ({ ...s, signOnSignature: id }));
  }

  function setActiveSignature (id) {
    setState(s => ({ ...s, activeSignature: id}));
  }

  function setPageScale (value) {
    setState(s => ({ ...s, pageScale: value }));
  }

  function setError (error) {
    setState(s => ({ ...s, error }));
  }

  const setSigned = useCallback(
    (key, value) =>  setState(s => ({
      ...s, 
      lastStamp       : (value ? key : undefined), 
      signOnSignature : null, 
      signed          : { ...s.signed, [key]: value} })),
    []
  );

  const setInitials = useCallback(
    (data, text) => {
      setState(s => ({ 
        ...s,
        initialsData: data, 
        initalsText: text
      }));

      //check if state can be stale / handle better
      if (data && state.signOnSignature) {
        setSigned(state.signOnSignature, true)
      }
    },
    [ setSigned, state.signOnSignature ]
  );

  const setSignature = useCallback(
    (data, text = null) => {
      setState(s => ({ 
        ...s,
        signatureData: data,
        signatureInit: true,
        signatureText: text
      }));

      //check if state can be stale / handle better
      if (data && state.signOnSignature) {
        setSigned(state.signOnSignature, true)
      }
    },
    [ setSigned, state.signOnSignature ]
  );

  const setComplete = useCallback(
    value => setState(s => ({ ...s, complete: value, [COMPLETE_DIALOG]: value })),
    []
  );

  // determine if we're creating/editing signature or intial
  const setSignatureType = useCallback(
    (type) =>  setState(s => ({ ...s, signatureType: type })),
    []
  )

  return (
    <DocumentStateContext.Provider value={{ ...state }}>
      <DocumentDispatchContext.Provider value={{ 
        setError,
        setSigned,
        setInitials,
        setSignature,
        setPageScale,
        closeDialog,
        openDialog,
        setComplete,
        setSignatureType,
        setActiveSignature,
        setSignOnSignature
      }}>
        {props.children}
      </DocumentDispatchContext.Provider>
    </DocumentStateContext.Provider>
  );
}
