import { useRef, useState, useEffect } from "react";

import { SCREEN_LARGE } from '../constants';

const MEDIA_QUERY = `(max-width: ${SCREEN_LARGE}px)`

const useBreakpoint = () => {
  const [ isMobile, setMobile ] = useState(false)

  useEffect(
    function matchQuery () {
      function onMediaChange (e) {
        setMobile(e.matches);
      }

      const media = window.matchMedia(MEDIA_QUERY);
      
      setMobile(media.matches);

      media.addEventListener("change", onMediaChange);

      return function () {
        media.removeEventListener("change", onMediaChange);
      }
    }, 
    []
  );

  return [ isMobile ];
}

export default useBreakpoint;