import { useEffect, useRef  } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

function usePageView () {
  const { pathname } = useLocation();
  const pathnameRef = useRef(null);

  useEffect(
    function onLocationChange () {
      if (ReactGA.isInitialized && pathnameRef.current != pathname) {
        pathnameRef.current = pathname;
        ReactGA.send({ hitType: "pageview", page: pathname, title: pathname });
      }
    }, 
    [ pathname, ReactGA.isInitialized ]
  );

  return null;
}

export default usePageView;
