import { useState, useContext, createContext } from 'react';
import useBreakpoint from '../hooks/useBreakpoint';

import config from '/src/config';

const AppStateContext       = createContext();
const AppDispatchContext    = createContext();

const INITIAL_STATE         = {};

export const useAppState    = () => useContext(AppStateContext);
export const useAppDispatch = () => useContext(AppDispatchContext);

export default function AppProvider (props) {
  const [ state, setState ] = useState(INITIAL_STATE); 
  const [ isMobile ]        = useBreakpoint();

  return (
    <AppStateContext.Provider value={{ ...state, isMobile }}>
      <AppDispatchContext.Provider value={{}}>
        {props.children}
      </AppDispatchContext.Provider>
    </AppStateContext.Provider>
  );
}
