const config = {
  platforms: {
    SA: {
      UI_URL: "https://sa.staging.reiformslive.com.au",
      API_URL: "https://sa-api.staging.reiformslive.com.au",
      declineEnabled: true,
    },
    FLSA: {
      UI_URL: "https://sa.formslive-staging.com.au",
      API_URL: "https://sa-api.formslive-staging.com.au",
      declineEnabled: true,
      checklistEnabled: true,

      theme: {
        icon: "fl-icon-inverted.png",
        logo: "fl-logo.png",
        "Theme-Color-Primary": "#4884ee",
        "Theme-Button-Primary": "#4884ee",
      },
    },
    NSW: {
      UI_URL: "https://nsw.staging.reiformslive.com.au",
      API_URL: "https://nsw-api.staging.reiformslive.com.au",
      declineEnabled: true,
      checklistEnabled: true,
    },
    NT: {
      UI_URL: "https://nt.staging.reiformslive.com.au",
      API_URL: "https://nt-api.staging.reiformslive.com.au",
      declineEnabled: true,
    },
    TAS: {
      checklistEnabled: true,
      declineEnabled: true,
      UI_URL: "https://tas.staging.reiformslive.com.au",
      API_URL: "https://tas-api.staging.reiformslive.com.au",
    },
    VIC: {
      checklistEnabled: true,

      UI_URL: "https://vic.staging.formslive.com.au",
      API_URL: "https://vic-api.staging.formslive.com.au",
      theme: {
        icon: "fl-icon-inverted.png",
        logo: "fl-logo.png",
        "Theme-Color-Primary": "#4884ee",
        "Theme-Button-Primary": "#4884ee",
      },
    },
    VICNEW: {
      checklistEnabled: true,
      declineEnabled: true,
      UI_URL: "https://vic.formslive-staging.com.au",
      API_URL: "https://vic-api.formslive-staging.com.au",
      theme: {
        icon: "fl-icon-inverted.png",
        logo: "fl-logo.png",
        "Theme-Color-Primary": "#4884ee",
        "Theme-Button-Primary": "#4884ee",
      },
    },
  },
  livesign: {
    scriptId: "__livesign_widget",
    url: "https://sandbox.widget.verify.live-sign.com",
  },
};

export default config;
