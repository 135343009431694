import React, { Component } from "react";
import { useRouteError} from 'react-router';

import ErrorImg from '../assets/error.svg';


export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch (error, errorInfo) {
    console.error(error, errorInfo);
  }

  render () {
    if (this.state.hasError) {
      return <ErrorDisplay />;
    }
    
    return this.props.children;
  }
}

export function RouteErrorBoundary () {
  const error = useRouteError();
  return <ErrorDisplay />;
}

export function ErrorDisplay () {
  return (
    <div className="max-w-3xl sm:max-w-4xl mx-auto px-4md:px-0">
      <div className="md:mt-16 bg-white p-10 border border-gray-100 border-b-gray-200 md:shadow-2xl rounded-lg flex flex-col flex-col-reverse md:flex-row">
        <div className="flex-1 pr-6">
          <h1 className="flex items-center flex-row text-3xl font-medium mb-6 text-zinc-800">
            <span data-cy="boundary-title">Something went wrong...</span>
          </h1>
          <div className="mt-6">
            <p data-cy="boundary-subheading" className="mb-4 text-lg">An error occurred loading the page.</p>
            <p data-cy="boundary-message" className="my-4 text-sm text-gray-500">
              We are most likley working on the problem. If the issue persists
              please contact support and we will assist you.
            </p>
          </div>
        </div>
        <div className="mb-4 md:mb-0 flex-1 max-w-md">
          <img src={ErrorImg} alt="Error Image"/>
        </div>
      </div>
    </div>
  );
}
