const config = {
  query: {
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        cacheTime: 1000 * 60 * 60 * 24, // 24 hours
      },
    },
  },
  theme: {
    "Theme-Color-Primary": "#2a7abe",
    "Theme-Button-Primary": "#2a7abe",
    logo: "reiformslivelogo.png",
    icon: "reiformsliveicon.svg",
  },
  ga: {
    trackingId: "G-W184R3T5WN",
    testMode: false,
    gtagOptions: {},
    gaOptions: {},
  },
  sentry: {
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    release: import.meta.env.VITE_RELEASE || "dev",
    environment: import.meta.env.VITE_NODE_ENV || "development",
    dsn: "https://0420c74fcd7b4c3599e67cde2960e295@sentry.formslive.com.au/15",
  },
};

export default config;
