import {
  useQuery, QueryClient,
  useQueryClient, QueryClientProvider
} from "react-query";

import config from '/src/config';
import { ReactQueryDevtools } from "react-query/devtools";

export const queryClient = new QueryClient(config.query);

function QueryProvider (props) {
  return (
    <QueryClientProvider client={queryClient}>
      {props.children}
      <ReactQueryDevtools />
    </QueryClientProvider>
  )
}

export default QueryProvider;